import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { toast } from 'react-toastify';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup } = useAuth();
  const navigate = useNavigate();

  const checkPasswordStrength = (password) => {
    const re = /^(?=.*[a-zäöüß])(?=.*[A-ZÄÖÜ])(?=.*\d)(?=.*[@$!%*?&#\-_+=<>.:,;])[A-Za-z\däöüßÄÖÜ@$!%*?&#\-_+=<>.:,;]{8,}$/;
    return re.test(password);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkPasswordStrength(password)) {
      toast.error('Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.');
      return;
    }

    const success = await signup(email, password);
    if (success) {
      toast.success('Konto erfolgreich erstellt!');
      navigate("/dashboard");
    } else {
      toast.error('Registrierung fehlgeschlagen');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col xs lg="6">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="E-Mail eingeben" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Passwort eingeben" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            
            <Button variant="primary" type="submit">
              Registrieren
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
