import React from 'react';
import { NavLink } from 'react-router-dom'; // Importiere NavLink statt Link
import { useAuth } from '../context/auth';
import LogoutButton from './Logout';
import './Nav.css';
import ReactGA from "react-ga";
function Nav() {
  const auth = useAuth();

  // Eine Funktion, die ausgeführt wird, wenn ein Benutzer auf einen Link klickt
  const handleNavLinkClick = (label) => {
    ReactGA.event({
      category: "Navigation",
      action: "Clicked Nav Link",
      label: label
    });
  };
  


  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="container">
        <a className="navbar-brand" href="/">
          Immosniper.de
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
          <ul className="navbar-nav flex-grow-1">
            {auth.user ? (
              <>
                <li className="nav-item">
                  <NavLink onClick={() => handleNavLinkClick("Dashboard")} className="nav-link" to="/dashboard">Dashboard</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => handleNavLinkClick("Favoriten")} className="nav-link" to="/favoriten">Favoriten</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={() => handleNavLinkClick("Suche")} className="nav-link"  to="/suche">Suche</NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink onClick={() => handleNavLinkClick("Suche")} className="nav-link"  to="/suche">Suche</NavLink>
                </li>
              </>
            )}
          </ul>
          <div className="d-flex align-items-center">
            {auth.user ? (
              <LogoutButton />
            ) : (
              <>
                <NavLink onClick={() => handleNavLinkClick("Login")} className="btn btn-outline-light my-2 my-sm-0 me-2" to="/login">Login</NavLink>
                <NavLink onClick={() => handleNavLinkClick("Register")} className="btn btn-outline-light my-2 my-sm-0" to="/register">Register</NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
