import React, { useState} from 'react';
import axios from 'axios';
import { Table, Button, Modal, Card} from 'react-bootstrap';
import './PropertySearch.css';
import moment from 'moment';
import { useAuth } from '../context/auth';
import ReactGA from 'react-ga';
const PropertySearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [propertyType, setPropertyType] = useState('alle');
  const [purchaseOrRent, setPurchaseOrRent] = useState('beides');
  const [properties, setProperties] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedInternId, setSelectedInternId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]);
  const [source, setSource] = useState('alle');
  const [showNegotiable, setShowNegotiable] = useState(false);
  const auth = useAuth();
  // Neue Zustandsvariable für die Zimmeranzahl
  const [roomCount, setRoomCount] = useState('alle');
  const [sortBy, setSortBy] = useState('price'); // Standardmäßig nach Preis sortieren


  const handleFavorite = (property) => {
    property.isFavorited = !property.isFavorited;
    // Zustand aktualisieren, um erneutes Rendering zu erzwingen
    setProperties(prevProperties => [...prevProperties]);
  
    // Hier können Sie den property.id an Ihr Backend senden, um die Änderung zu speichern
  };

  const formatPrice = (price) => {
    return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchProperties();
    }
  };

  const searchProperties = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/search`, {
        searchTerm,
        propertyType,
        purchaseOrRent,
        source,
        showNegotiable,
        roomCount,
        sortBy,
      });
      setProperties(response.data);
      // Erfassen Sie das Suchereignis mit react-ga
      ReactGA.event({
        category: 'User',
        action: 'Performed a property search',
        label: `Search term: ${searchTerm}, Type: ${propertyType}, Purchase/Rent: ${purchaseOrRent}, Source: ${source}`
      });
    } catch (error) {
      console.error('Fehler bei der Suche:', error);
    }
  };

  const openPriceHistoryModal = async (internId) => {
    setSelectedInternId(internId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/priceHistory`, {
        internId,
      });
      setPriceHistory(response.data);
    } catch (error) {
      console.error('Fehler bei der Abfrage des Preisverlaufs:', error);
    }
    setShowModal(true);
  };

  const closePriceHistoryModal = () => {
    setShowModal(false);
  };

  const arrowIcon = (priceChange) => {
    if (priceChange > 0) {
      return (
        <span
          className="material-icons"
          style={{
            color: 'red',
            fontSize: '24px',
            marginLeft: '3px',
            transform: 'scaleY(2.5)',
          }}
        >
          arrow_drop_up
        </span>
      );
    } else if (priceChange < 0) {
      return (
        <span
          className="material-icons"
          style={{
            color: 'green',
            fontSize: '24px',
            marginLeft: '3px',
            transform: 'scaleY(2.5)',
          }}
        >
          arrow_drop_down
        </span>
      );
    } else {
      return '';
    }
  };
  
  
  
  
  return (
    <div className="container mt-5">
      <h2>Suche nach Immobilien</h2>

      <div className="row d-flex align-items-end">
        <div className="col">
          <label htmlFor="searchTerm">Suche:</label>
          <input
            type="text"
            className="form-control"
            id="searchTerm"
            placeholder="PLZ oder Namen / Teilbegriffe"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="col-md-auto">
          <label htmlFor="purchaseOrRent">Mieten oder Kaufen:</label>
          <select
            className="form-control"
            id="purchaseOrRent"
            value={purchaseOrRent}
            onChange={(e) => setPurchaseOrRent(e.target.value)}
          >
            <option value="beides">Alle Anzeigen</option>
            <option value="kaufen">Kaufen</option>
            <option value="mieten">Mieten</option>
            <option value="shared">WG & kurzfristig</option>
          </select>
        </div>
        <div className="col-md-auto">
          <label htmlFor="propertyType">Immobilientyp:</label>
          <select
            className="form-control"
            id="propertyType"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
          >
            <option value="alle">Haus & Wohnung</option>
            <option value="haus">Haus</option>
            <option value="wohnung">Wohnung</option>
            <option value="geschäft">Für Firmen</option>
          </select>
        </div>
        <div className="col-md-auto">
        <label htmlFor="source">Quelle:</label>
        <select
            className="form-control"
            id="source"
            value={source}
            onChange={(e) => setSource(e.target.value)}
        >
            <option value="alle">Alle Quellen</option>
            <option value="immowelt">Immowelt</option>
            <option value="ebay">Ebay Kleinanzeigen</option>
            {/* Fügen Sie hier weitere Quellen hinzu, wenn Sie welche haben */}
        </select>
    </div>
    {/* Dropdown für die Zimmeranzahl */}
    <div className="col-md-auto">
          <label htmlFor="roomCount">Zimmeranzahl:</label>
          <select
            className="form-control"
            id="roomCount"
            value={roomCount}
            onChange={(e) => setRoomCount(e.target.value)}
          >
            <option value="alle">Alle Zimmeranzahlen</option>
            <option value="1">1 Zimmer</option>
            <option value="2">2 Zimmer</option>
            <option value="3">3 Zimmer</option>
            <option value="4">4 Zimmer</option>
            <option value="5+">5+ Zimmer</option>
          </select>
        </div>
    <div className="col-md-auto">
        <div className="form-check mt-4">
            <input
                className="form-check-input"
                type="checkbox"
                value={showNegotiable}
                id="showNegotiable"
                onChange={(e) => setShowNegotiable(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="showNegotiable">
                VB anzeigen
            </label>
        </div>
    </div>

        <div className="col-md-auto">
        <button className="btn btn-primary" onClick={searchProperties}>
            Suche
          </button>
        </div>

        {/* Dropdown für die Sortierung */}
  <div className="col-md-auto">
    <label htmlFor="sortBy">Sortieren nach:</label>
    <select
      className="form-control"
      id="sortBy"
      value={sortBy}
      onChange={(e) => setSortBy(e.target.value)}
    >
      <option value="price">Preis</option>
      <option value="room_count">Zimmeranzahl</option>
    </select>
  </div>
      </div>
      
      <div className="centered-text custom-size count-display">
        <strong>Gefundene Einträge:</strong> {properties.length}
      </div>
      
      

      {properties.length > 0 && (
        <div className="table-container">
        {properties.map((property, index) => (
          <Card className="mt property-card compact-card" key={index}>
          <div className="row no-gutters">
            <div className="col-md-4">
              <div className="image-container">
                <Card.Img src={property.imageURL || 'Testordner/my-app/src/pictures/drittes.png'} className="property-image" />
                <div className="overlay d-flex justify-content-between align-items-end p-2">
    <Button 
        className="favorite-button border-0"
        variant="color-contrast" 
        onClick={() => handleFavorite(property)}
        style={{ visibility: auth.user ? 'visible' : 'hidden' }}
    >
        {property.isFavorited ? '❤️' : '🤍'}
    </Button>
    <span className="image-count align-middle">1/n{property.imageCount}</span>
</div>
              </div>
            </div>
            <div className="col-md-8">
              <Card.Body className="d-flex flex-column justify-content-between">
                <div className="mb-3">
                  <div className="property-header d-flex justify-content-between">
                    <Card.Title>{property.title}</Card.Title>
                    <Card.Text className="compact-text"><strong>Quelle:</strong> {property.website}</Card.Text>
                  </div>
                  <div className="property-details d-flex justify-content-between mt-3">
                    <Card.Text className="compact-text"><strong>Ort:</strong> {property.location}</Card.Text>
                    <Card.Text className="compact-text"><strong>Preis:</strong> {property.price > 0 ? formatPrice(property.price) + '€' : 'Auf Anfrage'}</Card.Text>
                    <Card.Text className="compact-text"><strong>Fläche:</strong> {property.area} m²</Card.Text>
                    <Card.Text className="compact-text"><strong>Räume:</strong> {property.rooms}</Card.Text>
                  </div>
                </div>
                <div className="property-buttons d-flex justify-content-between mt-3">
                <Button
                      variant="info"
                      onClick={() => openPriceHistoryModal(property.intern_id)}
                      style={{ visibility: property.count - 1 > 0 ? 'visible' : 'hidden' }}
                  >
                      Preisverlauf
                  </Button>
                  <div className="changes-container d-flex align-items-center">
                    <strong>Preis Änderung:</strong>
                    <span className="count-container ml-2">
                      {property.count - 1} {arrowIcon(property.priceChange)}
                    </span>
                  </div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      let url;
                      if (property.website === 'immowelt') {
                        url = property.project === 0 ? `https://www.immowelt.de/expose/${property.intern_id}` : `https://www.immowelt.de/projekte/expose/${property.intern_id}`;
                      } else if (property.website === 'ebay') {
                        url = `https://www.kleinanzeigen.de/s-anzeige/${property.intern_id}`;
                      } else {
                        // Handle other sources if any.
                      }
                      // Google Analytics Event-Tracking
                      if (window.gtag) {
                        window.gtag('event', 'click', {
                          'event_category': 'External Link',
                          'event_label': property.website,
                          'value': property.intern_id
                        });
                      }
                      
                      window.open(url, '_blank');
                    }}
                  >
                    Zeig mir die Anzeige
                </Button>
                </div>
              </Card.Body>
            </div>
          </div>
        </Card>
        ))}
      </div>
      )}

      <Modal show={showModal} onHide={closePriceHistoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Preisverlauf für die Anzeige: {selectedInternId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Preis</th>
              </tr>
            </thead>
            <tbody>
              {priceHistory.map((priceEntry, index) => (
                <tr key={index}>
                  <td>{moment(priceEntry.last_seen).format('DD.MM.YYYY HH:mm:ss')}</td>
                  <td>{priceEntry.price > 0 ? formatPrice(priceEntry.price) + '€' : 'Auf Anfrage'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePriceHistoryModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PropertySearch;

