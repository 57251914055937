// Logout.js
import React from 'react';
import { useAuth } from '../context/auth.js';
import { toast } from 'react-toastify';

export default function LogoutButton() {
  const { signout } = useAuth();

  const handleSignout = async () => {
    const success = await signout();
    if (success) {
      toast.success("Du wurdest erfolgreich ausgeloggt.");
    } else {
      toast.error("Ein felher ist während des Logout passiert.");
    }
  }

  return (
    <button className="btn btn-outline-light my-2 my-sm-0" onClick={handleSignout}>Sign Out</button>
  );
}
