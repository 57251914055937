
import React from 'react';
import './Home.css';  // importiere die CSS-Datei

function Home() {
  return (
    <div className="home-container">
      <h1 className="home-title">Willkommen auf der Website!</h1>
      <div className="feature-card">
        <h2>Quellen</h2>
        <p>Aktuell werden als Quellen Ebay und Immowelt unterstützt, Immoscout ist noch in Bearbeitung.</p>
      </div>
      <div className="feature-card">
        <h2>Abdeckung</h2>
        <p>Aus Kosten Gründen werden aktuell nur Immobilien aus NRW angezeigt, bei Nachfrage besteht auch die Möglichkeit für eine Ausweitung auf ganz Deutschland</p>
      </div>
      <div className="feature-card">
        <h2>Suchfunktion</h2>
        <p>Die Suchfunktion unter Suche unterstützt Filterung nach Wohnung, Haus, WG & kurzfristige Angebote sowie Quelle, mieten und kaufen.</p>
      </div>
      <div className="feature-card">
        <h2>Vorteile für Eingeloggte Nutzer</h2>
        <p>Favorisiere deine Lieblingsanzeigen, lasse dir Statistiken für deine Wunschumgebung anzeigen</p>
      </div>
      <div className="feature-card">
        <h2>Kommende Features</h2>
        <p>Deutschland weite Statistiken, KI unterstützte Investment-Entscheidungen, und vieles mehr!</p>
      </div>
    </div>
  );
}

export default Home;
