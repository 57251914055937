import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setLoading(false);
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/checkAuth`, { headers: { Authorization: 'Bearer ' + token } });

        if (response.status === 200) {
          setUser(response.data);
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);


  const signin = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });

      if (response.status === 201) {
        localStorage.setItem('token', response.data.token); // store the token in local storage
        setUser(response.data.user);
        return true;
      } else {
        toast.error('Falsche E-Mail oder Passwort');
        return false;
      }
    } catch (error) {
      console.error("Signin failed:", error);
      return false;
    }
  };
  

  const signup = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, { email, password });

      
      if (response.status === 201) {
        localStorage.setItem('token', response.data.token);
        setUser(response.data.user);
        return true;
      } else {
        toast.error('Ein unbekannter Fehler ist aufgetreten');
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error('E-Mail wird schon benutzt');
      } else {
        console.error("Signup failed:", error);
      }
      return false;
    }
  };

  const signout = () => {
    localStorage.removeItem('token');
    setUser(null);
    return true;
  };
  

  return {
    user,
    signin,
    signup,
    signout,
    loading
  };
}
