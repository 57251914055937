// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Nav from './components/Nav';
import Search from './components/Suche';
import Login from './components/Login';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import { ProvideAuth } from './context/auth';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { redirectToProperDomain } from './components/Redirect';
function App() {
  // Initialisieren Sie Google Analytics beim ersten Rendern der App
  React.useEffect(() => {
    ReactGA.initialize('G-J3GHWCBDDP');
    // Verfolgen Sie den aktuellen Seitenaufruf
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Die Umleitungslogik hier aufrufen
    redirectToProperDomain();
    
  }, []);
  return (
    <ProvideAuth>
      <Router>
        <div>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/suche" element={<Search />} />
          </Routes>
          <ToastContainer />
        </div>
      </Router>
    </ProvideAuth>
  );
}

export default App;