import { Navigate } from "react-router-dom";
import { useAuth } from '../context/auth';
import ReactGA from "react-ga";

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  if (auth.loading) {
    return <div>Loading...</div>; // Oder irgendeine andere Ladeanzeige
  }
  if (auth.user) {
    // Verfolgen Sie den Seitenaufruf mit Google Analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute; // Achten Sie darauf, dass Sie dies haben