import React from 'react';
import PropertySearch from './PropertySearch';
import './Suche.css';

const Search = () => {
  return (
    <div className="header-image">
      <div className="search-page">
        <h2 className="text-center my-4">Immobilien-Suche</h2>
        <PropertySearch />
        {/* Hier können Sie die Suchergebnisse hinzufügen */}
      </div>
    </div>
  );
};

export default Search;
