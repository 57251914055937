// ./src/components/Dashboard.js

import React from 'react';

function Dashboard() {
  return (
    <div>
      <h1>Dashboard Seite</h1>
      {/* dashboard content goes here */}
    </div>
  );
}

export default Dashboard;
